






























import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator';
import Util from '@/lib/util';
import url from '@/lib/url'
import ListBase from '@/lib/listbase';
import PageRequest from '@/store/entities/page-request';
import ListView from '@/components/helper/list-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import BlacklistForm from './blacklist-form.vue';

class PageBlacklistRequest extends PageRequest {
    election: number = null;
    lawyer: number = null;
}

@Component({
    components: { ListView, FormBase, BlacklistForm }
})
export default class Blacklists extends ListBase {
    entity: string = 'blacklist';
    pagerequest: PageBlacklistRequest = new PageBlacklistRequest();
    filterForm: boolean = true;


    create() {
        this.modalShow = !this.modalShow;
    } 

    async afterSave() {
        let list: any = this.$refs.listView
        await list.getpage();
    }

    filterItems = [
        this.gItem({ type: 'object', prop: 'election', label: 'Eleccion', itemText: 'description', itemSubTitle: 'notes', itemChip: 'number', itemChipColor: 'state_color', size: 6, hideDetails: true, onchange: this.afterSave }),
        this.gItem({ type: 'object', prop: 'lawyer', label: 'Buscar Agremiado por Nombre, Matricula o Documento', itemText: 'description_full', autocomplete: true, searchLen: 2, size: 6, returnObject: false, hideDetails: true, onchange: this.afterSave }),
    ]

    columns = [
        this.gColO({ type: 'text', value: 'election.description', text: 'Eleccion', main: true }),
        this.gCol('lawyer.fullname', 'Agremiado', 'text'),
        this.gColO({ type: 'text', value: 'ban_type_id', text: 'Tipo de Bloqueo', generalType: true }),
        this.gCol('ip', 'IP', 'text'),
        this.defaultActions('view|delete')
    ];

    async created() {
        //this.getpage();            
    }
}