









import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import ModalView from '@/components/helper/modal-view.vue';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import url from '@/lib/url'
import Blacklist from '@/store/entities/elections/blacklist';
import ListBase from '@/lib/listbase';

@Component({
    components: { FormBase, ModalView }, 
    watch: {
        value: function (val) { 
            let obj = (this as any);
            obj.modalShow = val;
            if (val) {
                obj.blacklist = Util.extend(true, {}, this.$store.state.blacklist.editModel); 
                obj.initForm();
            } else 
                this.$emit('input', false);
        },
        modalShow: function (val) {
            if (!val) this.$emit('input', false);
        }
    }
})

export default class BlacklistForm extends ListBase {
    entity: string = 'blacklist';
    @Prop({ type: Boolean, default: false }) value: boolean;
    blacklist: Blacklist = new Blacklist();
    modalShow: boolean = false;
    modalSetting: any = {
        entity: "blacklist",
        title: 'IP',
        width: '600px',
        showForm: false,
    };
    
    get loading() {
        return this.$store.state[this.entity].loading;
    };

    get isEditing() {
        return this.blacklist.id > 0;
    }

    items = [
        this.gItem({ type: 'object', prop: 'election_id', label: 'Eleccion', size: 12, required: true, itemText: 'description' }),
        this.gItem({ type: 'object', prop: 'lawyer_id', label: 'Agremiado', itemText: 'description_full', autocomplete: true, searchLen: 2, size: 12, returnObject: false }),
        this.gItem({ type: 'object', prop: 'ban_type_id', label: 'Tipo de Bloque', size: 6, required: true, generalType: 'T_LOCK' }),
        this.gItem({ type: 'text', prop: 'ip', label: 'IP', required: true, size: 6 }),
    ];


    
    initForm() {
        if (!this.isEditing) {
            
        } else {
            
        }

        this.setItem(this.items, 'election_id', 'disabled', this.blacklist.election_id > 0);
    }

    async save() {
        if ((this.$refs.entityForm1 as any).validate()) {
            let model = Util.extend(true, {}, this.blacklist); 
            
            await this.$store.dispatch({
                type: this.entity + '/create',
                data: model
            });
            this.$emit('save-success');
            this.cancel();
            Util.abp.notify.success(this, 'IP Agregado ha la lista negra!');
                
        } 
    }        

    cancel() {
        this.$emit('input', false);
        (this.$refs.entityForm1 as any).reset();
    }

    mounted() {       

    }

    created() {
    }
}
